import React from 'react';
import Layout from "../components/layout/Layout";

const ToolDetail = ({ pageContext }) => {
  const { tool } = pageContext;

  if (!tool) {
    return <div>Tool not found</div>;
  }

  return (
    <Layout>
      <div className="flex flex-col items-center text-center p-8">
        {/* Header Section */}
        <p className="text-[12px]" style={{padding:"6px 32px",margin:"20px 0px",background:"#1351d81a",borderRadius:"24px",color:"#1351d8"}}>{tool.category}</p>
        <h1 className="text-5xl font-bold text-blue-600 mb-2">{tool.name}</h1>
        <p className="text-xl text-gray-600 mb-8 mt-8">
            {tool.description}
        </p>

        {/* Image Section */}
        <img
          src={tool.preview}
          alt={tool.name}
          className="w-full max-w-4xl h-64 object-cover mb-8"
        />

        {/* Introduction Section */}
        <div className="bg-[#F3F6FD] p-[30px] sm:p-[60px] rounded-lg shadow-lg w-full max-w-4xl text-left">
          <h2 className="text-3xl font-semibold mb-4">Introduction</h2>
          <p
            className="text-lg text-gray-700 mb-6"
            dangerouslySetInnerHTML={{ __html: tool.introduction }}
          />
          <a
            href={tool.link || '#'}
            className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Website
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ToolDetail;

